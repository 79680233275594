import { isMobile } from 'react-device-detect'
import { createBrowserRouter } from 'react-router-dom'
import AppLayout from './layout'
import { Callback } from './pages/callback'
import loadable from '@loadable/component'

// Lazy-loaded components
const ErrorPage = loadable(() => import('./pages/error-page'))
const Home = loadable(() => import('./pages/home'))
const KbaseChat = loadable(() => import('./pages/kbase-chat'))
const LoginPage = loadable(() => import('./pages/login'))
const MyKbase = loadable(() => import('./pages/my-kbase'))
const Search = loadable(() => import('./pages/search'))
const SearchCommunity = loadable(() => import('./pages/search-community'))
const UserSetting = loadable(() => import('./pages/user-setting'))

// Lazy-loaded layout
const AuthLayout = loadable(() => import('./layout/AuthLayout'))

// Lazy-loaded admin pages
const AdminPage = loadable(() => import('./pages/admin'))
const AdminLoginPage = loadable(() => import('./pages/admin/components/admin-login'))

// Lazy-loaded mobile components
const MobileCallback = loadable(() => import('./pages/callback/index.m'))
const MobileHome = loadable(() => import('./pages/home/index.m'))
const MobileKbaseChat = loadable(() => import('./pages/kbase-chat/index.m'))
const MobileMyKbase = loadable(() => import('./pages/my-kbase/index.m'))
const MobileSearch = loadable(() => import('./pages/search/index.m'))
const MobileUserSetting = loadable(() => import('./pages/user-setting/index.m'))

// Lazy-loaded other pages
const NotFound = loadable(() => import('./pages/not-found'))
const InvitePage = loadable(() => import('./pages/invite'))
const LangRedirect = loadable(() => import('./pages/lang-redirect'))

// Constants
import routes from '@constants/routes'
import I18nWrapper, { loader } from './pages/i18n-wrapper'

export const router = createBrowserRouter([
  {
    element: <I18nWrapper />,
    loader,
    children: [
      {
        path: '/:lang',
        element: <LangRedirect />,
      },
      {
        path: '/',
        element: <AppLayout />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: routes.INVITE_PAGE,
            element: <InvitePage />,
          },
          {
            path: '/callback',
            element: <Callback />,
            // element: isMobilePlatform ? <MobileCallback /> : <Callback />,
          },
          {
            path: 'admin/login',
            element: <AdminLoginPage />,
          },
          {
            path: 'admin/*',
            element: <AdminPage />,
          },
          {
            path: '/login',
            element: <LoginPage />,
          },
          {
            element: <AuthLayout />,
            children: [
              {
                path: '/',
                index: true,
                element: <Home />,

                // element: isMobilePlatform ? <MobileHome /> : <Home />,
              },
              {
                path: '/user/setting',
                element: <UserSetting />,
                // element: isMobilePlatform ? <MobileUserSetting /> : <UserSetting />,
              },
              {
                path: routes.TEMP_SEARCH,
                // element: isMobilePlatform ? <MobileSearch /> : <Search />,
                element: <Search />,
              },
              {
                path: routes.SEARCH,
                element: <Search />,
                // element: isMobilePlatform ? <MobileSearch /> : <Search />,
              },
              {
                path: '/search-community',
                element: <SearchCommunity />,
              },
              {
                path: '/kbase/chat/:kid',
                element: <KbaseChat />,
                // element: isMobilePlatform ? <MobileKbaseChat /> : <KbaseChat />,
              },
              {
                path: '/my-kbase',
                element: <MyKbase />,
                // element: isMobilePlatform ? <MobileMyKbase /> : <MyKbase />,
              },
            ],
          },
        ],
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
])
