import { LogtoConfig, UserScope } from '@logto/react'
import { API_URL } from './api'

export const logtoConfig: LogtoConfig = {
  // endpoint: 'https://y0wk7x.logto.app/',
  endpoint: 'https://auth.hika.fyi/',
  appId: '1t0v5pspw4dpofmoc8bz1',
  scopes: [
    UserScope.Email,
    UserScope.Phone,
    UserScope.CustomData,
    UserScope.Identities,
    // UserScope.Organizations,
  ],
  resources: [API_URL], // Add API resources
}
