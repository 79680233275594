import { getLocale } from '@constants/env'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: getLocale(),
    backend: {
      loadPath: '/locales/{{lng}}.json', // Path to translation files
    },
    interpolation: {
      escapeValue: false,
    },
  })

// let lang = null
// i18n.on('languageChanged', function (lng) {
//   // The language has been changed to 'lng'. Now you can perform any actions you need to keep things consistent.
//   if (lang && lang !== lng) {
//     location.reload()
//   }
// })
export default i18n
