// /api/user/api/user/

import { useCallback } from 'react'
import { API_URL } from '../../constants/api'
import { useAuthApi } from '../../hooks/useFetch'
import { handleRequest, HTTP_METHOD } from '../../utils/request'
import ky from 'ky'
import { IUpdateUserBody } from '../generated/model'
import { ICreateUserBody } from '@apis/generated/model'

export function useGetUserInfo() {
  const { authFetch, loading } = useAuthApi()

  const getUserInfo = useCallback(
    async (options) => {
      const promise = authFetch(`${API_URL}/user`, {
        method: HTTP_METHOD.GET,
        ...options,
      })
      return await handleRequest(promise)
    },
    [authFetch]
  )

  return { getUserInfo, loading }
}

export function useUpdateUserInfo() {
  const { authFetch, loading } = useAuthApi()

  const updateUserInfo = useCallback(
    async (param: IUpdateUserBody) => {
      const promise = authFetch(`${API_URL}/user`, {
        method: HTTP_METHOD.PATCH,
        json: param,
      })
      return await handleRequest(promise)
    },
    [authFetch]
  )

  return { updateUserInfo, loading }
}

export function usePostUserInfo() {
  const { authFetch, loading } = useAuthApi()

  const postUserInfo = useCallback(
    async (param: ICreateUserBody, options) => {
      const promise = authFetch(`${API_URL}/user`, {
        method: HTTP_METHOD.POST,
        json: param,
        ...options,
      })
      return await handleRequest(promise)
    },
    [authFetch]
  )

  return { postUserInfo, loading }
}

export async function adminLogin(user: { username: string; password: string }) {
  return await ky
    .post(`${API_URL}/admin/login`, {
      json: user,
    })
    .json()
}

export async function validateAdmin() {
  const token = sessionStorage.getItem('admin_auth_token')
  if (!token) {
    return false
  }

  const resp = (await ky
    .post(`${API_URL}/admin/validate_user`, {
      json: {
        token,
      },
    })
    .json()) as { message: string }

  const isValid = resp.message === 'Ok'
  if (isValid) {
    sessionStorage.setItem('admin_auth_token', token)
  }
  return isValid
}
