import { langCache } from '@utils/cache'
import { getLang } from '@utils/request'
import {
  isTablet,
  isMobile,
  isIOS,
  isIPad13, // Detect iPads running iPadOS 13+ (as they use a desktop-like user agent)
} from 'react-device-detect'

// Custom function to check if the device is an iPad
export const isPC = () => {
  // Treat iPad as a PC
  const isIpadDevice = isTablet && (isIPad13 || (isIOS && !isMobile))

  // Any other PC-specific checks
  const isDesktop = !isMobile && !isTablet

  return isIpadDevice || isDesktop
}

export const isMobilePlatform = !isPC()

export const isDev = process.env.NODE_ENV === 'development'

export enum Language {
  EN = 'en',
  CN = 'zh-CN',
  ES = 'es', //Spanish
  FR = 'fr', //French
  JA = 'ja', //Japanese
  DE = 'de', //German
  RU = 'ru', //Russian
}

export const LanguageMap = {
  [Language.EN]: 'English',
  [Language.CN]: '简体中文',
  [Language.ES]: 'Español',
  [Language.FR]: 'Français',
  [Language.JA]: '日本語',
  [Language.DE]: 'Deutsch',
  [Language.RU]: 'Pусский',
}

export function getLocale() {
  const lang = langCache.get() || getLang()
  return Object.values(Language).includes(lang) ? lang : Language.EN
}
