import { LogtoProvider } from '@logto/react'
import { Layout } from 'antd'
import { Outlet, useLocation } from 'react-router-dom'
import { logtoConfig } from '../constants/app'
import './index.css'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { langCache } from '@utils/cache'
import { getLang } from '@utils/request'

const { Content } = Layout
function AppLayout() {
  const location = useLocation()
  let lang = location.state?.lang

  if (lang) {
    lang = getLang(lang)
    window._language = lang
    langCache.set(lang)
  }
  return (
    <LogtoProvider config={logtoConfig}>
      <QueryParamProvider
        adapter={ReactRouter6Adapter}
        options={
          {
            // searchStringToObject: parse,
            // objectToSearchString: stringify,
          }
        }
      >
        <Layout>
          <Content
            style={{
              minHeight: '100vh',
            }}
          >
            <Outlet />
            {/* <Footer
            style={{
              textAlign: "center",
              backgroundColor: "transparent",
              marginInlineStart: 275,
            }}
          >
            Hika ©{new Date().getFullYear()} Created by Hika Team @Version
            - [{__VERSION__}-{__COMMIT_HASH__}]
          </Footer> */}
          </Content>
        </Layout>
      </QueryParamProvider>
    </LogtoProvider>
  )
}

export default AppLayout
