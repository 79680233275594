export const eventsMap = {
  CREATE_KBASE_START: 'create-kbase-start',
  CREATE_KBASE_LINK_START: 'create-kbase-link-start',
  CREATE_KBASE_FILE_START: 'create-kbase-file-start',
  CREATE_KBASE_ERROR: 'create-kbase-error',
  CREATE_KBASE_SUCCESS: 'create-kbase-success',
  KBASE_UPDATED: 'kbase-updated',
  CREATE_KBASE_DONE: 'create-kbase-done',
  RELOAD_KBASE_LIST: 'reload-kbase-list',

  STOP_TASK_LOOP: 'stop-task-loop',

  UPLOAD_FAILED: 'upload-failed',
  UPLOAD_DONE: 'upload-done',
  DELETE_UPLOAD_TASK: 'delete-upload-task',

  CREATION_ANIM_COMPLETE: 'creation-anim-complete',

  //topic
  SET_CURRENT_TOPIC: 'set-current-topic',
  REFRESH_TOPIC_LIST: 'refresh-topic-list',

  //topic end

  UPDATE_IMPORTING_TASK_DATA: 'update-importing-task-data',
  IMPORT_HISTORY_VISIBILITY_CHANGE: 'import-history-visibility-change',

  // search
  WEB_SEARCH_CALLBACK: 'web-search-callback',

  //modal
  SHOW_AUTH_MODAL: 'show_auth_modal',
}
