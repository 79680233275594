import LogtoClient from '@logto/browser'
import { logtoConfig } from '../constants/app'
import ky from 'ky'
import EventSourceStream from '@server-sent-stream/web'
import { IStreamChunk } from '../types/kbase'
import { message } from 'antd'
import { Language } from '@constants/env'
import { getAuthHeadersPromise, globalHeaders } from '../layout/components/auth-provider'
import { showAuthModal } from '@components/auth-modal'
import { langCache } from './cache'

export const logtoClient = new LogtoClient(logtoConfig)

export class CustomError extends Error {
  data: any
  constructor(message, data) {
    super(message) // 调用父类的构造函数并传递错误消息
    this.name = this.constructor.name // 设置错误名称为类名
    this.data = data // 自定义的附加数据
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor) // 捕获堆栈跟踪
    }
  }
}

export function errorHandler(err) {
  const errKey = err?.data?.err_key
  if (errKey) {
    switch (errKey) {
      case 'WEB_SEARCH_LIMIT':
        showAuthModal({
          hint: _t(errKey),
        })
        break
      default:
        break
    }
  } else {
    console.error(err)
  }
}

export enum HTTP_METHOD {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

export const request = ky.extend({
  timeout: 3 * 60 * 1e3,
  hooks: {
    beforeRequest: [
      async (request) => {
        try {
          const auth = request.headers.get('Authorization')

          const headers = auth ? null : (await getAuthHeadersPromise) && globalHeaders // await getAuthHeadersPromise
          // const hd = localStorage.getItem('_cache_header_')
          // Add the token to the Authorization header
          if (headers) {
            Object.entries(headers).forEach(([key, value]) => {
              request.headers.set(key, value)
            })
          }
        } catch (error) {
          console.error('Error retrieving token:', error)
        }
      },
    ],
    afterResponse: [
      async (_request, _options, response) => {
        if (response.status === 401) {
          location.href = '/login'
        } else if (response.status !== 200) {
          console.error('[Server Error]', response)
          // throw new Error(_t('serviceError'))
          //TODO: exception handling
          //TODO: show error
        }
      },
    ],
  },
})

export async function handleRequest(promise) {
  try {
    const [data, error] = (await promise) ?? []
    if (error) {
      console.error(error)
      return null
    }

    return data
  } catch (err) {
    console.error(err)
    throw err
  }
}

export async function handleResponse(response: Response): Promise<[any, Error | null]> {
  if (!response) {
    // navigate("/home");
    throw new Error('Unauthorized')
  }
  const status = response.status

  if (status === 500) {
    message.error(_t('serviceError'))
    throw new Error('Server error')
  } else if (status === 401) {
    location.href = '/login'
    throw new Error('Unauthorized')
  }
  const data = await response.json()

  if (!response.ok) {
    const error = new Error('errorsMap.' + data.err_code)
    throw error
  }
  return Promise.resolve(data.data)
}

export async function requestFn(loader, data = {}) {
  return loader(data).then(handleResponse)
}

export const getLang = (_lang?: string) => {
  const lang =
    _lang || window._language || langCache.get() || navigator.language || document.documentElement.lang || Language.EN

  const normalizedLang = lang.toLowerCase()

  if (/^zh(-|$)/i.test(normalizedLang)) return Language.CN // Chinese
  if (/^es(-|$)/i.test(normalizedLang)) return Language.ES // Spanish
  if (/^fr(-|$)/i.test(normalizedLang)) return Language.FR // French
  if (/^ja(-|$)/i.test(normalizedLang)) return Language.JA // Japanese
  if (/^de(-|$)/i.test(normalizedLang)) return Language.DE // German
  if (/^ru(-|$)/i.test(normalizedLang)) return Language.RU // Russian

  // Default to English
  return Language.EN
}

export const readStream = async (response, pipeFn?: (fn: (param: IStreamChunk) => IStreamChunk) => void) => {
  const DefaultValue: IStreamChunk = {
    loading: false,
    data: {
      answer: '',
      count: 0,
      references: [],
    },
    token: Date.now(),
  }
  pipeFn?.((data) => ({
    ...DefaultValue,
    ...data,
    loading: true,
  }))

  if (!response.body) return

  const decoder = new EventSourceStream()
  response.body.pipeThrough(decoder)
  const reader = decoder.readable.getReader()
  const readableStream = new ReadableStream({
    async start(controller) {
      function push() {
        reader.read().then(({ done, value }) => {
          if (done || value.data === '[DONE]') {
            pipeFn?.((data) => ({
              ...data,
              loading: false,
            }))
            controller.close()
            return
          }
          try {
            const parsedVal = JSON.parse(value.data)
            pipeFn?.((data) => {
              if (parsedVal?.chunk) {
                data.data.answer = (data.data.answer ?? '') + parsedVal.chunk
              } else {
                data.data = {
                  ...data.data,
                  ...parsedVal,
                }
              }
              return {
                ...data,
              }
            })
            // controller.enqueue(encoder.encode(parsedVal.chunk));
            push()
          } catch (e) {
            pipeFn?.((prev) => ({
              ...prev,
              loading: false,
            }))
            console.error(e)
            controller.close()
          }
        })
      }

      push()
    },
  })

  // const decoder = new EventSourceStream();
  // response.body.pipeThrough(decoder);
  // const reader = decoder.readable.getReader();
  // let answer = "";

  // pipeFn?.((prev: IStreamChunk) => ({
  //   ...DefaultValue,
  //   ...prev,
  //   loading: true,
  // }));

  // //TODO: kill it after changing page
  // while (true) {
  //   const { value, ...args } = await reader.read();
  //   // console.log("args", args, value);
  //   try {
  //     if (value.data === "[DONE]") {
  //       pipeFn?.((prev: IStreamChunk) => ({
  //         ...prev,
  //         loading: false,
  //       }));
  //       break;
  //     }
  //     const data = JSON.parse(value.data);

  //     console.log("chunk data", data);
  //     if (data.error) {
  //       console.log(data.error);
  //       return;
  //     }
  //     answer += data.chunk;
  //     pipeFn?.((prev: IStreamChunk) => ({
  //       ...prev,
  //       answer: prev.answer + data.chunk,
  //     }));
  //   } catch (e) {
  //     console.error(e);
  //     pipeFn?.((prev: IStreamChunk) => ({
  //       ...prev,
  //       loading: false,
  //     }));
  //   }
  // }

  return readableStream
}

export const pipeResponse = (response) => {
  const encoder = new TextEncoder()
  const decoder = new EventSourceStream()
  if (!response.body) return

  response.body.pipeThrough(decoder)
  const reader = decoder.readable.getReader()

  const readableStream = new ReadableStream({
    async start(controller) {
      function push() {
        reader.read().then(({ done, value }) => {
          if (done || value.data === '[DONE]') {
            controller.close()
            return
          }
          try {
            const parsedVal = JSON.parse(value.data)
            controller.enqueue(encoder.encode(parsedVal.chunk))
            push()
          } catch (e) {
            console.error('[ERROR] parse kbase chat message', e)
            controller.close()
          }
        })
      }

      push()
    },
  })

  return new Response(readableStream)
}
