import { usePostUserInfo } from '@apis/hooks/user'
import { API_URL } from '@constants/api'
import { getGuestId, HEADER_X_HIKA, HEADER_X_UID } from '@hooks/useGuestUser'
import { useHandleSignInCallback, useLogto } from '@logto/react'
import { redirectCache } from '@utils/cache'
import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

export default function useLogic() {
  const navigate = useNavigate()
  const { isAuthenticated, getIdTokenClaims, getAccessToken } = useLogto()
  const { postUserInfo } = usePostUserInfo()
  const [params] = useSearchParams()

  const { isLoading } = useHandleSignInCallback(async () => {
    const userInfo = await getIdTokenClaims()
    const token = await getAccessToken(API_URL)
    const guest = await getGuestId()

    postUserInfo(
      {
        email: userInfo.email,
        profile_image_url: userInfo.picture,
        name: userInfo.name,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          [HEADER_X_UID]: guest.uid,
          [HEADER_X_HIKA]: guest.hashId,
        },
      }
    )
    let redirect = params.get('redirect')
    redirect = (redirect && decodeURIComponent(redirect)) || redirectCache.get() || '/'
    try {
      const url = new URL(redirect)
      if (url.pathname === '/login') {
        redirect = '/'
      } else {
        redirect = url.pathname + url.search
      }
    } catch {
      redirect = '/'
    }
    navigate(redirect, { replace: true })
  })

  React.useEffect(() => {
    if (isAuthenticated && !isLoading) {
      navigate('/')
    }
  }, [isAuthenticated])

  return isLoading
}
