import { useLogto } from '@logto/react'
export default function useSignIn() {
  const { signIn } = useLogto()

  const handleSignIn = (params) => {
    localStorage.removeItem('token')
    params = params || {}
    params.redirectUri = `${location.origin}/callback`
    signIn(params)
  }

  return handleSignIn
}
