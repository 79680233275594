// useUser.ts
import { API_URL } from '@constants/api'
import { useLogto } from '@logto/react'
import eventBus from '@utils/event'
import { nanoid } from 'nanoid'
import { useEffect, useMemo, useState } from 'react'

const GUEST_ID_KEY = 'cache_guest_id'

async function sha256(message) {
  const encoder = new TextEncoder()
  const data = encoder.encode(message)

  // Compute the hash
  const hashBuffer = await crypto.subtle.digest('SHA-256', data)

  return Array.from(new Uint8Array(hashBuffer))
    .map((byte) => byte.toString(16).padStart(2, '0'))
    .join('')
}

export const getGuestId = async () => {
  let uid = localStorage.getItem(GUEST_ID_KEY)
  if (!uid) {
    uid = nanoid()
    localStorage.setItem(GUEST_ID_KEY, uid)
  }

  const hashId = crypto?.subtle ? await sha256(`#${uid}*`) : uid

  return {
    uid,
    hashId,
  }
}

export const HEADER_X_HIKA = 'x-hika'
export const HEADER_X_UID = 'x-uid'

const useGuestUser = () => {
  const { isAuthenticated, getAccessToken } = useLogto()
  const [isGuest, setIsGuest] = useState(!isAuthenticated)
  const [guest, setGuest] = useState(null)
  const [token, setToken] = useState(null)
  const [isRequesting, setIsRequesting] = useState(true)

  const afterUserInitCallback = (headers) => {
    eventBus.emit('_user_init_callback', {
      type: 'guest_mode',
      headers,
    })
  }

  const generateToken = () => {
    return new Promise((resolve) => {
      setGuest((guest) => {
        const header = {
          [HEADER_X_UID]: guest.uid,
          [HEADER_X_HIKA]: guest.hashId,
        }
        setToken(header)
        resolve(header)
        return guest
      })
    })
  }

  const initUser = async () => {
    const token = isAuthenticated && (await getAccessToken(API_URL))
    const isLogined = !!token
    setIsGuest(!isLogined)

    if (isLogined) {
      setGuest(null)
      return
    }
    const guest = await getGuestId()
    setGuest(guest)
    const header = await generateToken()
    afterUserInitCallback(header)
  }

  useEffect(() => {
    setIsRequesting(true)
    initUser()
      .catch(() => {
        afterUserInitCallback({})
      })
      .finally(() => {
        setIsRequesting(false)
      })
  }, [isAuthenticated])

  return useMemo(() => {
    return {
      guest,
      token,
      isRequesting,
      headers: token,
      isGuest,
    }
  }, [guest, token, isRequesting, isGuest])
}

export default useGuestUser
