import { langCache } from '@utils/cache'
import { Outlet, useLoaderData } from 'react-router-dom'

export default function I18nWrapper() {
  useLoaderData()
  return (
    <div>
      <Outlet />
    </div>
  )
}

export async function loader({ request, params }) {
//   const data = { some: 'thing' }

  if (params?.lang) {
    window._language = params.lang
    langCache.set(params.lang)
  }

  return {}
  //   const resp = await new Response(JSON.stringify(data), {
  //     status: 200,
  //     headers: {
  //       'Content-Type': 'application/json; utf-8',
  //     },
  //   })
  //   return await resp.json()
}
