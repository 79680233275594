import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ConfigProvider, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { RouterProvider } from 'react-router-dom'
import './index.css'
import { router } from './routes'
import './utils/sentry'
import eventBus from '@utils/event'
import React, { useEffect } from 'react'
import './i18n/lazy'
import { langCache } from '@utils/cache'
import { Language } from '@constants/env'
import { Suspense } from 'react'
import { getLang } from '@utils/request'

function addLangClassToBody(lang) {
  const classList = document.body.classList

  // Remove all classes ending with '-context'
  ;[...classList].forEach((cls) => {
    if (cls.endsWith('-context')) {
      classList.remove(cls)
    }
  })

  // Add the new language-specific context class
  classList.add(`${lang}-context`)
}

function useLocale() {
  const { i18n } = useTranslation()
  const [locale, setLocale] = React.useState(i18n.language)

  useEffect(() => {
    addLangClassToBody(i18n.language)
    return eventBus.on('change_language', (lang) => {
      if (i18n.language !== lang && Object.values(Language).includes(lang)) {
        window._language = lang
        langCache.set(lang)
        setLocale(lang)
        addLangClassToBody(lang)
        i18n.changeLanguage(lang)
      }
    })
  }, [i18n])
  return locale
}

export function App() {
  const { t } = useTranslation()
  const queryClient = new QueryClient()
  const locale = useLocale()
  const currentLocale = getLang(locale)

  if (!window._t) {
    window._t = t
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        locale={currentLocale}
        theme={{
          components: {
            Layout: {
              headerBg: 'white',
              bodyBg: 'transparent',
            },
          },
          token: {
            // Seed Token
            // colorPrimary: 'rgba(0, 0, 0, 0.88)',
            borderRadius: 2,
            // Alias Token
            // colorBgContainer: "#f6ffed",
          },
        }}
      >
        <RouterProvider router={router} />
      </ConfigProvider>
    </QueryClientProvider>
  )
}

export default function AppWrap() {
  return (
    <Suspense fallback={<Spin fullscreen />}>
      <App />
    </Suspense>
  )
}
