import { Button, Space } from 'antd'
import styles from './index.module.scss'
import useSignIn from '@hooks/useSignIn'
import { useState } from 'react'

export default function GuestUser(props) {
  const { before = null } = props
  const [loading, setLoading] = useState(false)
  const signIn = useSignIn()

  const onClick = (params) => {
    setLoading(true)
    signIn(params)
  }

  return (
    <div className="flex items-center flex-col">
      <div style={{ marginBottom: 40, marginTop: 35 }}>
        <img width={54} src="/icon.jpg" />
      </div>
      {before}
      <div className={styles.loginTitle}>{_t('login_to_use_more_func')}</div>
      <Space direction="vertical" className="w-full" style={{ gap: 10 }}>
        <Button
          loading={loading}
          className={styles.signinBtn}
          onClick={() => {
            onClick({
              firstScreen: 'register',
            })
          }}
        >
          {_t('sign_up')}
        </Button>
        <Button loading={loading} className={styles.loginBtn} onClick={() => onClick({})}>
          {_t('login')}
        </Button>
      </Space>
    </div>
  )
}
