import { Modal, ModalProps } from 'antd'
import GuestUser from '../../layout/components/guest-user'
import eventBus from '@utils/event'
import { eventsMap } from '@constants/events'

interface IProps {
  modal: ModalProps
  config: {
    hint?: string
  }
}

export default function AskForAuthModal(props: IProps) {
  return (
    <Modal footer={null} open={true} {...props.modal}>
      <GuestUser
        before={
          props.config?.hint ? <div className="mb-[10px] text-[#9F9F9F] text-[14px]">{props.config.hint}</div> : null
        }
      />
    </Modal>
  )
}

export const showAuthModal = (config: { hint: string }) => {
  eventBus.emit(eventsMap.SHOW_AUTH_MODAL, config)
}
