import { IdTokenClaims, useLogto } from '@logto/react'
import { userInfoCache } from '@utils/cache'
import eventBus from '@utils/event'
import { logtoClient } from '@utils/request'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useGetUserInfo, useUpdateUserInfo } from '../apis/hooks/user'
import { API_URL } from '@constants/api'
import { Language } from '@constants/env'

const useUser = () => {
  const [user, setUser] = useState<IdTokenClaims & { info: Record<string, any>; avatarUrl?: string }>()
  const { getUserInfo } = useGetUserInfo()
  const { isAuthenticated, getIdTokenClaims, getAccessToken, isLoading } = useLogto()
  const { updateUserInfo, loading } = useUpdateUserInfo()
  const [token, setToken] = useState(() => localStorage.getItem('token'))
  const [isRequesting, setIsRequesting] = useState(true)

  const queryUser = useCallback(
    async (token) => {
      try {
        const cachedUser = userInfoCache.get()
        if (cachedUser?.info) {
          setUser(cachedUser)
          return
        }
      } catch {
        //do nth
      }

      if (loading) return

      const claims = await getIdTokenClaims()
      const userInfo = await getUserInfo({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      const user = { ...claims, info: userInfo }
      user.avatarUrl = user?.info?.profile_image_url || user?.picture
      user.name = user.name || user.info?.name
      // TODO: 修复旧数据
      if (user.info?.language === 'CN') {
        user.info.language = Language.CN
      }

      userInfoCache.set(user)
      setUser(user)
    },
    [token, getUserInfo]
  )

  const getLogtoToken = async () => {
    if (!isAuthenticated) {
      return null
    }

    const token = await getAccessToken(API_URL)

    if (!token) {
      await logtoClient.clearAccessToken()
      return
    }

    return token
  }

  const generateToken = async () => {
    const token = await getLogtoToken()
    if (!token) return
    setToken(token)
    localStorage.setItem('_token_', token)
    return token
  }

  const initUser = async () => {
    if (!isAuthenticated) {
      afterUserInitCallback()
      return
    }
    const token = await generateToken()
    afterUserInitCallback(token)

    if (token) {
      await queryUser(token)
    }
  }

  const afterUserInitCallback = (token = null) => {
    setIsRequesting(false)
    eventBus.emit('_user_init_callback', {
      type: 'auth_user',
      token,
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : null,
    })
  }

  useEffect(() => {
    if (isLoading && !isAuthenticated) {
      afterUserInitCallback()
      return
    }

    if (!isAuthenticated) {
      setIsRequesting(false)
      setToken(null)
      afterUserInitCallback()
      return
    }
    setIsRequesting(true)
    initUser().finally(() => {
      setIsRequesting(false)
    })

    return eventBus.on('_user_change_locacle', (language) => {
      setUser((user) => {
        if (user?.info?.uid) {
          updateUserInfo({
            ...user?.info,
            language,
          })
        }

        return user
      })
    })
  }, [isAuthenticated])

  const refreshToken = async () => {
    const accessToken = await getLogtoToken()
    accessToken && setToken(accessToken)
  }

  useEffect(() => {
    if (!isAuthenticated) {
      return
    }

    const timer = setInterval(refreshToken, 5 * 60 * 1e3)
    return () => {
      clearInterval(timer)
    }
  }, [isAuthenticated])

  return useMemo(() => {
    return {
      user,
      token,
      isRequesting,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      updateUserInfo: async (...args) => {
        await updateUserInfo(...args)
        queryUser(token)
      },
    }
  }, [user, token, isRequesting, updateUserInfo, queryUser])
}

export default useUser
