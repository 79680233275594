import useGuestUser from '@hooks/useGuestUser'
import { Spin } from 'antd'
import clsx from 'clsx'
import { createContext, useContext } from 'react'
import useUser from '../../../hooks/useUser'
import LoginPage from '../../../pages/login'
import styles from './index.module.scss'
import eventBus from '@utils/event'
import { getLang } from '@utils/request'

interface IUserState {
  token?: any
  isRequesting?: boolean
  headers?: Record<string, any>
}

export const AuthContext = createContext<{
  token?: string
  headers?: Record<string, any>
  user?: IUserState & any
  updateUserInfo?: any
  guest?: IUserState & any

  lang?: string
}>({ token: null })

export let globalHeaders = null
export const getAuthHeadersPromise = (() => {
  let callback = null

  const userTypeSet = new Set()
  const userTypes = {}
  eventBus.on('_user_init_callback', (userHeaders) => {
    userTypeSet.add(userHeaders.type)
    userTypes[userHeaders.type] = userHeaders
    globalHeaders = userTypes['auth_user']?.headers || userTypes['guest_mode']?.headers
    if (globalHeaders) {
      callback(globalHeaders)
    }

    if (userTypeSet.size === 2) {
      callback(globalHeaders)
    }
  })
  return new Promise((resolve) => {
    callback = resolve
  })
})()

export const AuthProvider = ({ children }) => {
  const userState = useUser()
  const guest = useGuestUser()

  const getToken = () => {
    if (userState?.user) {
      return userState.token
    }

    return guest?.token
  }

  const getHeaders = () => {
    const header = userState?.user ? userState.headers : guest.headers
    localStorage.setItem('_cache_header_', JSON.stringify(header))
    return header
  }

  const isRequesting = userState?.isRequesting || guest.isRequesting
  const token = getToken()
  const headers = getHeaders()

  const render = () => {
    if (isRequesting) {
      return (
        <div className={clsx('flex w-screen h-screen justify-center items-center', styles.wrap)}>
          <Spin size="large" />
        </div>
      )
    }
    return children
    if (userState.user || guest?.isGuest) {
      return children
    }

    return <LoginPage />
  }

  const lang = userState?.user?.info?.language || getLang()
  return <AuthContext.Provider value={{ token, headers, ...userState, guest, lang }}>{render()}</AuthContext.Provider>
}

export function useGlobalAuth() {
  return useContext(AuthContext)
}
